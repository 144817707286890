import React, { useEffect, useState } from 'react';
import Footer from '../layouts/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function LeaveDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData]  = useState([]);
    useEffect(() => {
        if(localStorage.getItem('token')){
            const data = {
                token    : localStorage.getItem('token'),
                id       : location.state.id
            }
            axios.post('employee/leave/details', data)
            .then((response) => {
                let data = response.data.data;
                console.log(data);
                setData(data);
            })
        }else{
          window.location.replace('login');
        }
      }, []);
    return (
    <>
        <div style={{ backgroundColor : '#115F8F',padding: '30px 20px 10px 20px', cursor : 'pointer', color : 'white'}} onClick={ () => navigate(-1)}><p> <i className="fas fa-arrow-left"></i> <span style={{ paddingLeft: '20px'}}> Leave </span></p> </div>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className=''>
                        <table className='table'>
                            <tr>
                                <th>Status</th>
                                <td> <p style={{ backgroundColor : data.status == 'Approved' ? 'green' : '#115F8F', color : 'white', padding : '10px 20px', textAlign : 'center', borderRadius : '10px'}}>{data.status}</p></td>
                            </tr>
                            <tr>
                                <th>Requested on</th>
                                <td> { data.applied_date } </td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td> {data.type ? data.type.type : null } </td>
                            </tr>
                            <tr>
                                <th>Period</th>
                                <td> { data.from } to { data.to } </td>
                            </tr>
                            <tr>
                                <th>Total Days</th>
                                <td> {data.total} </td>
                            </tr>
                            <tr>
                                <th>Reason</th>
                                <td> {data.reason} </td>
                            </tr>
                            <tr>
                                <th>Approvers</th>
                                <td> {data.approved_by} </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
    );
}
