import axios from 'axios';
import React, { useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

export default function LeaveForm() {
    const location = useLocation();
    const navitate = useNavigate();
    const [type, setType]         = useState(location.state.id);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate]     = useState('');
    const [reason, setReason]     = useState('');
    const submit = () => {
        let data = {
            token       : localStorage.getItem('token'),
            leave_type  : type,
            from_date   : fromDate,
            to_date     : toDate,
            reason      : reason
        };
        if(fromDate && toDate && reason){
            axios.post('employee/leave/apply', data)
            .then((response) => {
                let resData = response.data.response;
                console.log(resData.status);
                
                if(resData.status == 200){
                    alert(resData.message);
                    navitate(-1);
                }else{
                    alert(resData.message);
                }
                
            })
        }else{
            alert('Went Wrong');
        }
        
    }
  return (
    <>
        <div style={{ backgroundColor : '#115F8F',padding: '30px 20px 10px 20px', cursor : 'pointer', color : 'white'}} onClick={ () => navitate(-1)}><p> <i className="fas fa-arrow-left"></i> <span style={{ paddingLeft: '20px'}}> Leave </span></p> </div>
        <div className='container'>
            <div className='row pt-5'>
                <div className='col-12'>
                    <div className='form-group mb-2'>
                        <label> Form Date </label>
                        <input type='date' className='form-control' name='start_date' onChange={(e) => { setFromDate(e.target.value)}} value={fromDate} />
                    </div>
                </div>
                <div className='col-12'>
                    <div className='form-group mb-2'>
                        <label> To Date </label>
                        <input type='date' className='form-control' name='to_date' onChange={(e) => { setToDate(e.target.value)}} value={toDate}/>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='form-group mb-2'>
                        <label> Reason </label>
                        <textArea className="form-control" name="reason" rows="5" onChange={(e) => { setReason(e.target.value)}} value={reason}></textArea>
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <button className='btn btn-primary w-100' onClick={submit} style={{ backgroundColor : '#115F8F'}}> Submit </button>
                </div>
            </div>
        </div>
    </>
  );
}
