import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

export default function Login() {
  const navigate = useNavigate();
  const { innerWidth: width, innerHeight: height } = window;
  const [empId, setEmpId]           = useState('');
  const [password, setPassword]     = useState('');
  const submit = async (e) => {
    { e.preventDefault();}
      
    const data = {
      emp_id    : empId,
      password  : password,
    }
    axios.post('employee/login', data)
    .then((response) => {
        // console.log(response);
        let data = response.data;
        if(data.response.status == 200){
          localStorage.setItem('token',response.data.data.token);
          setEmpId('');
          setPassword('');
          // navigate("/home");
          window.location.replace('gretings');
        }else{
          alert(data.response.message);
          setPassword('');
        }
        
        // window.location.assign('/');
    })
    .catch((error) => {
        console.log(error);
    });
    
  }
  return (
    <>
      <div style={{ backgroundColor : '#115F8F',width : width }}>
        <div style={{ width : '100%'}}>
          <center>
            <img src='/logo512b.png' style={{ width : width, padding: '0% 20%'}}/>
          </center>
        </div>
      </div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12'>
            <div className='py-2'>
              <input type="text" className='form-control' placeholder='employee ID' onChange={(e) => { setEmpId(e.target.value)}}
            value={empId} />
            </div>
            <div className='py-2'>
              <input type="password" className='form-control' placeholder='employee Password'  onChange={(e) => setPassword(e.target.value)}
            value={password}/>
            </div>
            <div className='py-2 mt-3'>
              <button className='btn btn-danger w-100' onClick={submit} style={{ backgroundColor : '#115F8F', border : '1px solid #115F8F'}}> <i className='fas fa-login'></i>Login </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
