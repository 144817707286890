import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/home/home';
import AppRoutes from './components/layouts/appRoutes';
import Login from './components/auth/Login';

function App() {
  let data;
  const { innerWidth: width, innerHeight: height } = window;

  if(localStorage.getItem('token')){
    data = <AppRoutes />;
  }else{
    data = <Login />;
  }
  return (
    <div className="App">
        <div className="root" style={{ height : height, overflow : 'hidden'}}>
          <div style={{ height : height-20, overflow : 'scroll'}}>
          { data }
          </div>
        </div>
    </div>
  );
}

export default App;
