import React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';

export default function Payroll() {
  return (
    <>
        <Header />
        <div> Coming soon ..... </div>
        <Footer />
    </>
  );
}
