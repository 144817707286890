import React, { useEffect, useState } from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function PhoneBook() {
    const [datas, setData]          = useState([]);
    const navigate                  = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('token')){
        const data = {
            token    : localStorage.getItem('token')
        }
        axios.post('employee/contact', data)
        .then((response) => {
            let data = response.data.data;
            console.log(data);
            setData(data);
        })
        }else{
            window.location.replace('login');
        }
    }, []);
    const makeCall = (e) => {
        window.open('tel:'+e);
    }
    const makeEmail= (e) => {
        window.open('mailto:'+e);
    }
  return (
    <>
    <Header />
    <div>
    {/* <div className="row mt-3">
        <div className="col-sm-12 col-12 px-3"> 
            <select className="form-control" name="department" style={{borderRadius: '40px'}}>
                <option value=""> Select Department </option>
            </select>
        </div>
    </div> */}
    <div className="row mt-3" style={{marginBottom: '100px',padding: '15px 0px'}}>
        <div className="col-12">
            <table className="table tbl-attn">
                { datas.map((data, i) =>
                    <>
                        <tr>
                            <th colSpan={2}>
                                <p style={{ color : 'lightgray', fontWeight : 'bold'}}>{data.name}</p>
                            </th>
                        </tr>
                        { data.employee.map((empData, i) =>
                        <tr>
                            <td>
                                <h4>{empData.f_name}</h4>
                                <p style={{ float : 'left'}}>{empData.phone}</p>
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                                <div style={{textAlign: 'right',display: 'flex'}}>
                                    <p style={{padding: '10px'}} onClick={() => makeEmail(empData.email)} ><i className="fa-regular fa-comment-dots" style={{color: '#115F8F',fontSize: '20px'}}></i></p>
                                    <p style={{padding: '10px'}} onClick={() => makeCall(empData.phone)} ><i className="fas fa-phone" style={{color: '#181818',fontSize: '20px'}}></i></p>
                                </div>
                            </td>
                        </tr>
                        )}
                    </>
                )}
            </table>
        </div>
    </div>
    </div>
    <Footer />
    </>
  );
}
