import React, { useEffect, useState } from 'react';
import Footer from '../layouts/footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Profile() {
  const navigate = useNavigate();
    const [data, setData] = useState('');
    useEffect(() => {
      if(localStorage.getItem('token')){
        let data = {
          token    : localStorage.getItem('token')
        }
        axios.post('employee/profile', data)
        .then((response) => {
            let resData = response.data.data;
            console.log(resData.employee);
            setData(resData.employee);
        })
      }else{
        window.location.replace('login');
      }
    }, []);
    const edit = () =>{
      navigate('/profile-edit', { state : {data: data}});
    }
    const logout = () => {
      localStorage.removeItem('token');
      navigate('/login');
    }
  return (
    <>
      <div className='row' style={{ backgroundColor: '#115F8F'}}>
        <center>
          <div className='col-4 pt-5'>
              <div className='profile-pic'>
                <div style={{ width : '90%', aspectRatio : 1, overflow : 'hidden', borderRadius : '200px'}}>
                  <img src={data.image} className='w-100'/>
                </div>
                <i className='fas fa-pencil' onClick={edit} style={{ cursor : 'pointer'}}></i>
              </div>
            </div>
            <div className='py-3'>
              <h3 className='text-white'> {data.f_name} </h3>
              <p className='text-white'> {data.designation}</p>
              <p className='btn btn-primary my-2' style={{ border : '1px solid white', backgroundColor : '#115F8F'}} onClick={ logout }> Logout </p>
            </div>
        </center>
      </div>
      <div className='container pb-5' style={{ paddingBottom : '100px'}}>
        <div className='col-md-12 py-5'>
          <div className='profile-panel'>
            <p> Email </p>
            <p> {data.email} </p>
          </div>
          <div className='profile-panel'>
            <p> Designation </p>
            <p> {data.designation}</p>
          </div>
          <div className='profile-panel'>
            <p> Department </p>
            <p> {data.department}</p>
          </div>
          <div className='profile-panel'>
            <p> Manager </p>
            <p> { data.manager ? data.manager : 'N/A'} </p>
          </div>
          <div className='profile-panel'>
            <p> Date Of Joining </p>
            <p> {data.join_date} </p>
          </div>
          <div className='profile-panel'>
            <p> Employee Type </p>
            <p> {data.emp_type}</p>
          </div>
          <div className='profile-panel'>
            <p> Employee Id </p>
            <p> {data.emp_id}</p>
          </div>
          <div className='profile-panel'>
            <p> Gender </p>
            <p> {data.gender} </p>
          </div>
          <div className='profile-panel mt-3' style={{ backgroundColor : '#115F8F'}}>
            <p className='text-white text-center w-100' onClick={edit} style={{ cursor : 'pointer'}}> <i className='fas fa-edit'></i> &nbsp; Edit Profile </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
