import React, { useEffect, useState } from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function LeaveList() {
  const [datas, setData]  = useState([]);
  const navigate          = useNavigate();
  useEffect(() => {
    if(localStorage.getItem('token')){
      const data = {
        token    : localStorage.getItem('token')
      }
      axios.post('employee/leave/list', data)
      .then((response) => {
          let data = response.data.data;
          console.log(data.myLeave);
          setData(data.myLeave);
      })
    }else{
      window.location.replace('login');
    }
  }, []);
  const getDetails = (id) => {
    navigate('/leave-details', {state : {id:id}});
  }
  return (
    <>
        <Header />
        <div class="row" style={{marginBottom: '100px'}}>
          <div class="col-12 mt-3 mb-3" style={{display: 'flex',justifyContent: 'flex-end',paddingRight: '25px'}}>
              <Link to="/leave-apply" style={{ backgroundColor : '#115F8F', color : 'white', padding : '10px 20px', borderRadius : '20px'}}> Apply Leave </Link>
          </div>
          <div class="col-12">
              <table class="table table-bordered">
                {datas.map((data, i) =>
                  <tr onClick={() => getDetails(data.id)} style={{ cursor : 'pointer'}}>
                      <td>
                          <h4>{data.type.type}</h4>
                      </td>
                      <td>
                        <p>{data.from} To {data.to}</p>
                      </td>
                      <td>
                          <p style={{ backgroundColor : data.status === 'Approved' ? 'green' :'#115F8F', color : 'white', borderRadius : '10px', textAlign : 'center'}}> {data.status} </p>
                      </td>
                  </tr>
                )}
              </table>
          </div>
        </div>
        <Footer />
    </>
  );
}
